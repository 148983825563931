import api from '../api/nb-iot';
import { pollingNbIotDataInterval, pollingNbIotDataMaxDuration } from '../constants';

const getDefaultState = () => ({
  errorMessage: '',
  isLoading: false,
  isPolling: false,
  pollingIntervalId: null,
  data: null,
  activationData: null,
  locations: null,
  temperatures: null,
  lastGotDataAt: null,
  pollingStartedAt: 0,
  exceededMaxPollingDuration: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) { Object.assign(state, getDefaultState()); },
    SET_ERROR_MESSAGE(state, errorMessage) { state.errorMessage = errorMessage; },
    CLEAR_ERROR_MESSAGE(state) { state.errorMessage = ''; },
    START_LOADING(state) { state.isLoading = true; },
    STOP_LOADING(state) { state.isLoading = false; },
    START_POLLING(state) {
      state.isPolling = true;
      state.pollingStartedAt = Date.now();
    },
    STOP_POLLING(state) { state.isPolling = false; },
    SET_DATA(state, data) {
      state.data = data;
      state.lastGotDataAt = Date.now();
    },
    SET_ACTIVATION_DATA(state, data) {
      state.activationData = data;
    },
    SET_LOCATIONS(state, locations) {
      if (JSON.stringify(locations) !== JSON.stringify(state.locations)) {
        // only update if there's a difference
        state.locations = locations;
      }
    },
    SET_TEMPERATURES(state, temperatures) {
      if (JSON.stringify(temperatures) !== JSON.stringify(state.temperatures)) {
        // only update if there's a difference
        state.temperatures = temperatures;
      }
    },
    SET_POLLING_INTERVAL_ID(state, pollingIntervalId) {
      state.pollingIntervalId = pollingIntervalId;
    },
    RESET_POLLING_INTERVAL_ID(state) { state.pollingIntervalId = null; },
    EXCEEDED_MAX_POLLING_DURATION(state) { state.exceededMaxPollingDuration = true; },
  },
  actions: {
    async activateNbIotLabel(context, {
      token, name, bleId, location,
    }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      context.commit('START_LOADING');
      try {
        const activationData = await api.activateNbIotLabel(
          token,
          name,
          bleId,
          location,
        );
        context.commit('SET_ACTIVATION_DATA', activationData);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async getNbIotAsset(context, { token, assetId, bleId }) {
      context.commit('CLEAR_ERROR_MESSAGE');
      if (!context.state.isPolling) {
        context.commit('START_LOADING');
      }
      try {
        const data = await api.getNbIotAsset(
          token,
          assetId,
          bleId,
        );
        context.commit('SET_DATA', data);
        context.commit('SET_LOCATIONS', data.locations);
        context.commit('SET_TEMPERATURES', data.temperatures);
      } catch (error) {
        context.commit('SET_ERROR_MESSAGE', error.message);
      } finally {
        context.commit('STOP_LOADING');
      }
    },
    async startPolling(context, { token, assetId, bleId }) {
      context.commit('RESET_POLLING_INTERVAL_ID');
      if (pollingNbIotDataInterval && !context.state.isPolling) {
        const pollingIntervalId = window.setInterval(async () => {
          try {
            // check whether max duration exceeded
            if (Date.now() - context.state.pollingStartedAt >= pollingNbIotDataMaxDuration) {
              context.dispatch('stopPolling');
              context.commit('EXCEEDED_MAX_POLLING_DURATION');
            } else if (!context.state.isLoading) {
              await context.dispatch('getNbIotAsset', { token, assetId, bleId });
            }
          } catch (error) {
            // error
          }
        }, pollingNbIotDataInterval);
        context.commit('START_POLLING');
        context.commit('SET_POLLING_INTERVAL_ID', pollingIntervalId);
      }
    },
    stopPolling(context) {
      window.clearInterval(context.state.pollingIntervalId);
      context.commit('STOP_POLLING');
      context.commit('RESET_POLLING_INTERVAL_ID');
    },
  },
  getters: {
  },
};
