/* eslint-disable import/prefer-default-export */

import { labelProvisioned } from '../constants';
import { groupAssetInSpacesOnSpaceName } from './space-name-grouping-helper';
import { constructDate, constructTime } from './date-formatting-helper';

import colours from '../styles/_updated-variables.scss';
import constructDisplayAddress from './nb-iot-current-location-helper';

const BROWSER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

function constructEvent(
  isoDate,
  title,
  text,
  colour,
  isoDate2 = null,
  timeZone = BROWSER_TIME_ZONE,
  isStart = false,
) {
  const date = new Date(isoDate);

  const event = {
    startTimestamp: date.toISOString(),
    startDate: timeZone ? constructDate(date, false, timeZone) : constructDate(date),
    startTime: timeZone ? constructTime(date, timeZone) : constructTime(date),
    title,
    text,
    color: colour,
    timeZone,
    isStart,
  };

  if (isoDate2) {
    const date2 = new Date(isoDate2);
    event.endTimestamp = date2.toISOString();
    event.endDate = timeZone ? constructDate(date2, false, timeZone) : constructDate(date2);
    event.endTime = timeZone ? constructTime(date2, timeZone) : constructTime(date2);
  }

  return event;
}

// Combine any consecutive spells that are same space / group
function coalesceSpells(spells, spaceGroupingOnName, useFacilityName) {
  const coalescedEvents = [];

  spells
    // reverse time order (i.e. latest first)
    .sort((a, b) => new Date(b.enteredOn) - new Date(a.enteredOn))
    .forEach((spell) => {
      const previous = coalescedEvents.length > 0
        ? coalescedEvents[coalescedEvents.length - 1]
        : null;

      if (!previous || (
        (spaceGroupingOnName ? spell.groupName : spell[useFacilityName ? 'facilityName' : 'spaceName'])
          !== (spaceGroupingOnName ? previous.groupName : previous[useFacilityName ? 'facilityName' : 'spaceName']))) {
        coalescedEvents.push(spell);
      } else {
        // combine the time periods
        previous.enteredOn = spell.enteredOn;
      }
    });
  return coalescedEvents;
}

export function constructTimelineItemsForAsset(
  asset,
  assetInSpacesOrFacilities,
  includeLabelProvision,
  spaceGroupingOnName = false,
  coalesceEnterExitSpells = false,
  useFacilityName = false,
) {
  const assetsInSpacesOrFacilitiesCopy = JSON.parse(JSON.stringify(assetInSpacesOrFacilities));

  let enterExitSpells = spaceGroupingOnName
    ? groupAssetInSpacesOnSpaceName(assetsInSpacesOrFacilitiesCopy)
    : assetsInSpacesOrFacilitiesCopy;

  if (coalesceEnterExitSpells) {
    enterExitSpells = coalesceSpells(enterExitSpells, spaceGroupingOnName, useFacilityName);
  }

  const events = [];

  const chooseGroupSpaceOrFacilityName = (
    enterExitSpell,
    _spaceGroupingOnName,
    _useFacilityName,
  ) => {
    if (_spaceGroupingOnName) {
      return enterExitSpell.groupName;
    }
    return _useFacilityName ? enterExitSpell.facilityName : enterExitSpell.spaceName;
  };
  enterExitSpells.forEach((enterExitSpell) => {
    events.push(constructEvent(
      enterExitSpell.enteredOn,
      chooseGroupSpaceOrFacilityName(enterExitSpell, spaceGroupingOnName, useFacilityName),
      enterExitSpell.spaceNames ? enterExitSpell.spaceNames.join(', ') : '',
      enterExitSpell.hasExited ? colours.gray : colours.active,
      enterExitSpell.hasExited ? enterExitSpell.exitedOn : null,
      enterExitSpell.facilityTimeZone || BROWSER_TIME_ZONE,
    ));
  });

  if (includeLabelProvision && asset.labelAddedOn) {
    events.push(constructEvent(
      asset.labelAddedOn,
      'Label provisioned',
      `Label linked to ${asset.name}`,
      colours.start,
    ));
  }
  return events;
}

export function constructLocationTimelineItemsForAsset(
  asset,
  includeLabelProvision = false,
  includeState = false,
) {
  const assetLocations = [];
  const dedupedLocations = [];
  const locations = ((asset.locations || {}).items || [])
    .filter((loc) => loc.address)
    .map((loc) => ({
      ...loc,
      displayName: constructDisplayAddress(loc.address, true),
    }))
    // ensure locations are sorted by timestamp in descending order
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  if (locations.length > 0) {
    for (let i = locations.length - 1; i >= 0; i--) {
      const item = locations[i];
      if (item.displayName) {
        assetLocations.push({
          timestamp: item.timestamp,
          lastUpdatedAt: item.lastUpdatedAt || item.timestamp,
          stillAtLocation: i === locations.length - 1,
          name: item.displayName.name,
          subTitle: item.displayName.subTitle,
        });
      }
    }

    dedupedLocations.push({
      arrivedAt: assetLocations[0].timestamp,
      departedAt: assetLocations[0].lastUpdatedAt,
      name: assetLocations[0].name,
      subTitle: assetLocations[0].subTitle,
      stillAtLocation: true,
    });

    if (assetLocations.length > 1) {
      for (let i = 1; i < assetLocations.length; i++) {
        const prevLocation = dedupedLocations[dedupedLocations.length - 1];

        if (prevLocation.name === assetLocations[i].name) {
          // merge with previous location
          prevLocation.departedAt = assetLocations[i].lastUpdatedAt;
        } else {
          // new location
          dedupedLocations.push({
            arrivedAt: assetLocations[i].timestamp,
            departedAt: assetLocations[i].lastUpdatedAt,
            name: assetLocations[i].name,
            subTitle: assetLocations[i].subTitle,
            stillAtLocation: true,
          });

          prevLocation.stillAtLocation = false;
        }
      }
    }
  }

  const events = [];
  if (dedupedLocations.length > 0) {
    for (let i = dedupedLocations.length - 1; i >= 0; i--) {
      const loc = dedupedLocations[i];
      events.push(constructEvent(
        loc.arrivedAt,
        loc.name,
        includeState ? loc.subTitle : '',
        loc.stillAtLocation ? colours.active : colours.gray,
        loc.departedAt,
      ));
    }
  }

  if (includeLabelProvision && asset.labelAddedOn) {
    events.push(constructEvent(
      asset.labelAddedOn,
      labelProvisioned,
      `Linked to ${asset.name}`,
      colours.start,
      undefined,
      undefined,
      true,
    ));
  }

  return events;
}
