<template>
  <div
    ref="container"
    class="charts-and-buttons-container"
  >
    <button
      v-if="includeDownload"
      class="temperature-chart-button"
      :style="{right: '65px'}"
      @click="exportData"
    >
      <v-icon
        color="black"
        size="30"
      >
        mdi-download
      </v-icon>
    </button>
    <button
      v-if="isFullScreen"
      class="temperature-chart-button"
      @click="() => $emit('closeFullScreen')"
    >
      <v-icon
        color="black"
        size="30"
      >
        mdi-close
      </v-icon>
    </button>
    <button
      v-else
      class="temperature-chart-button"
      @click="() => $emit('openFullScreen')"
    >
      <v-icon
        color="black"
        size="30"
      >
        mdi-arrow-expand-all
      </v-icon>
    </button>
    <div
      class="charts-container"
      :style="{
        'height': `${chartHeight}px`,
      }"
    >
      <v-chart
        :key="`temperature_${chartKey}`"
        :option="chartOptions.temperature"
        group="temperature-chart-group"
        class="temperature-line-chart"
        autoresize
        @datazoom="(d) => onChartZoom(charts.temperature, d)"
      />
      <v-chart
        :key="`assetSpace_${chartKey}`"
        :style="{
          'height': `${assetSpaceChartHeight}px`,
        }"
        :option="chartOptions.assetSpace"
        group="temperature-chart-group"
        autoresize
        @datazoom="(d) => onChartZoom(charts.assetSpace, d)"
      />
      <v-chart
        :key="`miniMap_${chartKey}`"
        :option="chartOptions.miniMapAndAxis"
        class="miniMap"
        group="temperature-chart-group"
        autoresize
        @datazoom="(d) => onChartZoom(charts.miniMapAndAxis, d)"
      />
    </div>
    <resize-observer @notify="setWrapperHeight" />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
import { constructDate, constructTime, constructCsvDateTime } from '../../../helpers/date-formatting-helper';
import colours from '../../../styles/_updated-variables.scss';
import { convertCelsiusToFahrenheit, temperatureUnits } from '../../../constants';

use([TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer]);

const msInMin = 60 * 1000;

const SPACE_AFTER_TEMPERATURE_TOLERANCE_MS = 10 * msInMin;
const NOW_POINT_THRESHOLD_MS = 6 * 60 * msInMin;

function dateFormatter(value) {
  const labelDateTime = new Date(value);
  return `${constructDate(labelDateTime)}\n${constructTime(labelDateTime)}`;
}

const NUM_TICKS = 6;
const INTERVALS = [0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4, 5, 10, 15, 20, 30, 50, 100, 200];
function findMinAndMaxTemp(temperatureData, isCelsius) {
  const temperatures = temperatureData
    .map((td) => (isCelsius
      ? td.degreesCelcius
      : convertCelsiusToFahrenheit(td.degreesCelcius)));

  const min = Math.min(...temperatures);
  const max = Math.max(...temperatures);

  let interval = Math.ceil((max - min) / NUM_TICKS);

  // find nearest interval
  for (let i = 0; i < INTERVALS.length; i += 1) {
    if (INTERVALS[i] > interval) {
      interval = INTERVALS[i];
      break;
    }
  }

  // round to nearest interval
  return {
    min: Math.floor(min / interval) * interval,
    max: Math.ceil(max / interval) * interval,
    interval,
  };
}

const miniMap = {
  type: 'slider', // Slider zoom
  // filterMode: 'filter',
  filterMode: 'none', //  prevent line segments disappearing when end points are outside of visible range.
  // showDetail: false, //  hide timestamps showing when hovering over handles in slider
  // minimum distance between zoom handles to
  minSpan: 0.1,
  labelFormatter: dateFormatter,
  textStyle: {
    fontSize: 10,
  },
  showDataShadow: false, // hide background
};

export default {
  name: 'TemperatureChart',
  components: {
    VChart,
  },
  props: {
    assetName: { type: String, required: true },
    bleId: { type: Number, required: true },
    temperatureData: { type: Array, required: true },
    assetSpaceData: { type: Array, required: true },
    isFullScreen: { type: Boolean, default: false },
    fullScreenWindowHeight: { type: Number, default: 0 },
    includeDownload: { type: Boolean, default: false },
  },
  data() {
    return {
      wrapperHeight: 0,
      assetSpaceChartHeight: 0,
      containerWidth: 0,
      chartKey: 0,
      chartZoom: {
        type: 'inside',
        filterMode: 'none', //  prevent line segments disappearing when end points are outside of visible range.
        // minimum distance between zoom handles to
        // prevent bug when handles are both 0 or both 100
        minSpan: 0.5,
        start: 0,
        end: 100,
      },
    };
  },
  computed: {
    charts() {
      return {
        temperature: 'temperature',
        assetSpace: 'assetSpace',
        miniMapAndAxis: 'miniMapAndAxis',
      };
    },
    isCelsius() { return this.$store.getters['user/isCelsiusTemperatureUnits']; },
    chartOptions() {
      const { min, max, interval } = findMinAndMaxTemp(this.chronTempData, this.isCelsius);

      const options = {
        temperature: {
          grid: {
            top: 55,
            bottom: 10, // Remove whitespace from bottom of chart.
            left: 70, // removing margin from left side of chart for full-screen mode
            right: 70, // removing margin from right side of chart for full-screen mode
          },
          xAxis: {
            type: 'time',
            show: false,
            splitLine: {
              show: false, // Hide grid lines
            },
            max: this.lastSpaceEventLimitIso,
          },
          yAxis: {
            type: 'value',
            name: `Temperature (${this.isCelsius ? temperatureUnits.celsius : temperatureUnits.fahrenheit})`,
            nameTextStyle: {
              fontWeight: 'bold',
              align: 'center',
            },
            min,
            max,
            interval,
          },
          tooltip: {
            trigger: 'axis', // dotted line when hovering over points to axis
            confine: true,
            formatter: (params) => {
              const timestamp = new Date(params[0].value[0]);
              const temperature = this.isCelsius
                ? params[0].value[1].toFixed(1)
                : params[0].value[1].toFixed(0); // Fahrenheit - no decimal places
              const zonesCurrentlyIn = ((this.timestampToZonesAndFacility[timestamp.toISOString()] || {}).zones || []).join(', ');
              return `
              <div style="text-align: left;">
                <strong>${this.assetName}</strong>
                <br/>${`${constructTime(timestamp)}, ${constructDate(timestamp)}`}
                <br/>${`${temperature}${this.isCelsius ? temperatureUnits.celsius : temperatureUnits.fahrenheit}`}
                <br/><strong>In Zones</strong>
                <br/>${zonesCurrentlyIn}
              </div>
            `;
            },
          },
          dataZoom: [this.chartZoom],
          series: [
            {
              data: this.chronTempData.map((td) => [
                td.timestamp,
                this.isCelsius
                  ? td.degreesCelcius
                  : convertCelsiusToFahrenheit(td.degreesCelcius)]),
              type: 'line',
              name: 'Temperature Data',
              lineStyle: {
                color: colours.gray1,
              },
              showSymbol: false,
              itemStyle: {
                color: colours.tealBright,
              },
              symbolSize: 10,
              symbol: 'circle',
              animation: false,
            },
          ],
        },
        assetSpace: {
          grid: {
            top: 10, // Remove whitespace from top of chart
            bottom: 0,
            left: 70, // removing margin from left side of chart for full-screen mode
            right: 70, // removing margin from right side of chart for full-screen mode
          },
          xAxis: {
            type: 'time',
            show: false,
            // Ensures range of assetSpaceHistory time axis matches
            // range of temperature chart time axis.
            min: this.timeExtremes.earliest,
            max: this.lastSpaceEventLimitIso,
          },
          yAxis: {
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              verticalAlign: 'bottom',
              align: 'left',
              fontWeight: 'bold',
              margin: 30,
              padding: [0, 0, 10, 0],
            },
          },
          tooltip: {
            confine: true,
            formatter: (params) => {
              const timestamp = new Date(params.data.value[0]);
              const spaceName = params.data.value[1];
              const isEnter = params.data.value[2];
              return `
                      <div style="text-align: left;">
                        <strong>${isEnter ? 'ENTER' : 'EXIT'} ${spaceName} </strong>
                        <br/>${`${constructTime(timestamp)}, ${constructDate(timestamp)}`}
                      </div>
                `;
            },
          },
          dataZoom: [this.chartZoom],
          series: this.cleanAssetInSpaceHistory.map((episode) => ({
            type: 'line',
            name: episode.spaceName,
            lineStyle: { color: colours.tealLight },
            symbol: 'circle',
            animation: false,
            data: [
              {
                value: [
                  episode.enter,
                  episode.spaceName,
                  true, // isEnter
                ],
                symbolSize: episode.enterIsInTimeRange ? 10 : 0,
                itemStyle: { color: colours.tealLight },
              },
              {
                value: [
                  episode.exit,
                  episode.spaceName,
                  false, // isEnter
                ],
                symbolSize: (episode.exitIsInTimeRange || episode.unexitedAndTempDataRecent)
                  ? 10 : 0,
                itemStyle: {
                  color: episode.unexitedAndTempDataRecent
                    ? colours.tealDark : colours.tealLight,
                },
              },
            ],
          })),
        },
        miniMapAndAxis: {
          grid: {
            height: 0,
            top: 0,
            bottom: 0,
            left: 70, // removing margin from left side of chart for full-screen mode
            right: 70, // removing margin from right side of chart for full-screen mode
          },
          xAxis: {
            type: 'time',
            position: 'bottom',
            name: 'Time\n(UTC)',
            nameTextStyle: {
              fontWeight: 'bold',
              align: 'center',
              verticalAlign: 'top',
            },
            axisLabel: {
              formatter: dateFormatter,
              hideOverlap: true,
              margin: 10,
            },
            axisLine: {
              show: true,
              onZero: false,
              lineStyle: {
                color: colours.gray2,
              },
            },
            axisTick: {
              show: true,
              interval: 2,
            },
            max: this.lastSpaceEventLimitIso,
          },
          yAxis: {
            show: false,
          },
          dataZoom: [this.chartZoom, miniMap],
          // minimap does not have correct x-axis scaling
          // see: https://github.com/apache/echarts/issues/19930
          series: [
            {
              data: this.chronTempData.map((td) => [
                td.timestamp,
                this.isCelsius
                  ? td.degreesCelcius
                  : convertCelsiusToFahrenheit(td.degreesCelcius),
              ]),
              type: 'line',
              lineStyle: { color: 'transparent' },
              showSymbol: false,
              symbol: 'none',
            },
          ],
        },
      };

      if (this.chronTempData.length < 2) {
        // remove settings that require multiple data points
        delete options.temperature.yAxis.min;
        delete options.temperature.yAxis.max;
        delete options.temperature.yAxis.interval;

        // remove dataZoom
        delete options.temperature.dataZoom;
        delete options.assetSpace.dataZoom;
        delete options.miniMapAndAxis.dataZoom;
      }

      return options;
    },
    chronTempData() {
      return (JSON.parse(JSON.stringify(this.temperatureData)))
        .map((d) => ({
          timestamp: d.timestamp,
          degreesCelcius: d.degreesCelcius,
        }))
        .reverse();
    },
    temperatureChartIsNarrow() { return this.containerWidth < 600; },
    spacesCount() { return new Set(this.assetSpaceData.map((episode) => episode.spaceId)).size; },
    cleanAssetInSpaceHistory() {
      return this.assetSpaceData
        .filter((episode) => episode.enteredOn < this.lastSpaceEventLimitIso
          && (!episode.exitedOn || episode.exitedOn > this.timeExtremes.earliest))
        .map((episode) => {
          const enterIsInTimeRange = episode.enteredOn >= this.timeExtremes.earliest;
          const hasExited = !!episode.exitedOn;
          const exitIsInTimeRange = hasExited && episode.exitedOn <= this.lastSpaceEventLimitIso;

          const cleanAssetInSpace = {
            enter: enterIsInTimeRange ? episode.enteredOn : this.timeExtremes.earliest,
            exit: exitIsInTimeRange ? episode.exitedOn : this.lastSpaceEventLimitIso,
            spaceName: episode.spaceName,
            facilityName: episode.facilityName,
            facilityTimeZone: episode.facilityTimeZone,
            enterIsInTimeRange,
            exitIsInTimeRange,
          };

          if (!hasExited && new Date() - new Date(this.lastSpaceEventLimitIso)
            < (NOW_POINT_THRESHOLD_MS)) {
            cleanAssetInSpace.exit = this.lastSpaceEventLimitIso;
            cleanAssetInSpace.unexitedAndTempDataRecent = true;
          }

          return cleanAssetInSpace;
        });
    },
    timeExtremes() {
      if (this.chronTempData.length === 0) {
        const now = new Date().toISOString();
        return {
          earliest: now,
          latest: now,
        };
      }
      // we can assume there is at least one temperature data point
      const data = {
        earliest: this.chronTempData[0].timestamp,
        latest: this.chronTempData[this.chronTempData.length - 1].timestamp,
      };
      data.duration = new Date(data.latest) - new Date(data.earliest);

      return data;
    },
    lastSpaceEventLimitIso() {
      if (Date.now() - new Date(this.timeExtremes.latest)
        < SPACE_AFTER_TEMPERATURE_TOLERANCE_MS) {
        return new Date().toISOString();
      }
      return this.timeExtremes.latest;
    },
    timestampToZonesAndFacility() {
      // create map of timestamp to zones and facility
      const timetampsAndTemps = this.chronTempData.map((td) => [td.timestamp, td.degreesCelcius]);
      const inZonesAndFacility = {};

      const sortedAssetSpaceData = JSON.parse(JSON.stringify(this.cleanAssetInSpaceHistory))
        .sort((a, b) => new Date(a.enter) - new Date(b.enter));

      timetampsAndTemps.forEach(([timestamp, degreesCelcius]) => {
        const zones = [];
        let facility = null;

        const timestampMs = new Date(timestamp).getTime();

        sortedAssetSpaceData.forEach((episode) => {
          // check if timestamp is in time range of episode
          if (new Date(episode.enter).getTime() <= timestampMs
            && (!episode.exit || new Date(episode.exit).getTime() >= timestampMs)) {
            zones.push(episode.spaceName);

            if (episode.facilityName) {
              // overwrite facility if there is a facility
              //  - this is the last facility in the time range i.e. the most recent
              facility = {
                facilityName: episode.facilityName,
                facilityTimeZone: episode.facilityTimeZone,
              };
            }
          }
        });

        inZonesAndFacility[timestamp] = {
          timestamp,
          degreesCelcius: this.isCelsius
            ? degreesCelcius
            : convertCelsiusToFahrenheit(degreesCelcius),
          zones: [...new Set(zones)].sort(), // remove duplicates and sort alphabetically
          facilityName: facility ? facility.facilityName : null,
          facilityTimeZone: facility ? facility.facilityTimeZone : null,
        };
      });

      return inZonesAndFacility;
    },
    chartHeight() {
      return this.isFullScreen ? this.fullScreenWindowHeight : this.wrapperHeight;
    },
  },
  watch: {
    // Watch for chart crossing threshold width
    temperatureChartIsNarrow(chartIsNarrow) {
      this.adjustChartStyleForChartWidth(chartIsNarrow);
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.updateContainerWidth);
  },
  mounted() {
    this.updateContainerWidth();
    window.addEventListener('resize', this.updateContainerWidth);

    this.adjustChartStyleForChartWidth(this.temperatureChartIsNarrow);

    this.$nextTick(() => {
      this.setWrapperHeight();
      this.setAssetSpaceChartHeights();
    });
  },
  methods: {
    setWrapperHeight() {
      this.wrapperHeight = this.$refs.container.clientHeight;
    },
    setAssetSpaceChartHeights() {
      // each space should take up 40px in the asset-space chart
      this.assetSpaceChartHeight = this.spacesCount * 40;
    },
    adjustChartStyleForChartWidth(chartIsNarrow) {
      this.chartOptions.miniMapAndAxis.xAxis.nameTextStyle.fontSize = chartIsNarrow ? 10 : 12;
      this.chartOptions.miniMapAndAxis.xAxis.axisLabel.fontSize = chartIsNarrow ? 10 : 12;

      this.chartOptions.temperature.yAxis.nameTextStyle
        .padding = chartIsNarrow ? [32, -20, 0, 0] : null;
      this.chartKey += 1;
    },
    updateContainerWidth() {
      // Use $nextTick to ensure the DOM is updated
      this.$nextTick(() => {
        this.containerWidth = this.$refs.container.offsetWidth;
      });
    },
    onChartZoom(chart, data) {
      const isMiniMap = chart === this.charts.miniMapAndAxis;
      this.chartZoom.start = isMiniMap ? data.start : data.batch[0].start;
      this.chartZoom.end = isMiniMap ? data.end : data.batch[0].end;

      this.setTempChartYAxisRange(this.chartZoom.start, this.chartZoom.end);
    },
    setTempChartYAxisRange(xAxisStart, xAxisEnd) {
      let lowerHandleTempIndex;
      let upperHandleTempIndex;

      const totalDurationInMs = new Date(this.timeExtremes.latest)
      - new Date(this.timeExtremes.earliest);
      this.chronTempData.forEach((td, index) => {
        const currentTimeSinceEarliestTemp = new Date(td.timestamp)
         - new Date(this.timeExtremes.earliest);
        if (currentTimeSinceEarliestTemp / totalDurationInMs
        >= (xAxisStart / 100)
        && !lowerHandleTempIndex) {
          lowerHandleTempIndex = Math.max(index - 1, 0);
        }
        if (currentTimeSinceEarliestTemp / totalDurationInMs
          >= (xAxisEnd / 100)
        && !upperHandleTempIndex) {
          upperHandleTempIndex = Math.min(index, this.chronTempData.length - 1);
        }
      });
      // filter temperature data in time range
      const tempDataInTimeRange = this.chronTempData
        .slice(lowerHandleTempIndex, upperHandleTempIndex + 1);

      // find max and min
      const { min, max, interval } = findMinAndMaxTemp(tempDataInTimeRange, this.isCelsius);

      // set y-axis range
      if (!Number.isNaN(min) && !Number.isNaN(max)) {
        this.chartOptions.temperature.yAxis.min = min;
        this.chartOptions.temperature.yAxis.max = max;
        this.chartOptions.temperature.yAxis.interval = interval;
      }
    },
    exportData() {
      // construct CSV
      const headerRow = [
        'Timestamp (UTC)',
        'Asset Name',
        'BLE ID',
        'Space Names',
        `Temperature (${this.isCelsius ? temperatureUnits.celsius : temperatureUnits.fahrenheit})`,
        'Facility Name',
        'Facility Time Zone',
        'Local Facility Time',
      ];

      const rows = [headerRow];

      Object.values(this.timestampToZonesAndFacility)
        // sort by timestamp (most recent first)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .forEach((inZonesAndFacility) => {
          const {
            timestamp, zones, degreesCelcius, facilityName, facilityTimeZone,
          } = inZonesAndFacility;

          const quotesIfMultipleZones = zones.length > 1 ? '"' : '';
          rows.push([
            constructCsvDateTime(timestamp),
            this.assetName,
            this.bleId,
            zones.length > 0 ? `${quotesIfMultipleZones}${zones.join(',')}${quotesIfMultipleZones}` : '',
            this.isCelsius ? degreesCelcius : convertCelsiusToFahrenheit(degreesCelcius),
            facilityName || '',
            facilityTimeZone || '',
            facilityTimeZone ? constructCsvDateTime(timestamp, facilityTimeZone) : '',
          ]);
        });

      const csvContent = rows.map((row) => row.join(',')).join('\n');

      // download CSV
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = `${this.assetName}-temperature-data.csv`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
.charts-and-buttons-container {
  position: relative;
  width: 100%;
  height: 100%
}
.charts-container {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 200px;
}
.temperature-line-chart {
  height: 60%;
  min-height: 200px;
}
.miniMap {
  height: 110px;
  margin-bottom: 10px;
}
.temperature-chart-button {
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: 1;
  margin: 10px;
  border-radius: 15%;
  border-style: solid;
  border-color: $grey-1;
  border-width: 3px;
}
</style>
