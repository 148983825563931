<template>
  <Dialogue
    :is-visible="isVisible"
  >
    <v-card
      flat
      class="settings-content-container"
    >
      <v-card-title>
        Settings
      </v-card-title>
      <v-card-text>
        <table>
          <tr>
            <td width="150px">
              Temperature units
            </td>
            <td>
              <v-btn-toggle
                v-model="temperatureUnits"
                dense
                @change="setTemperatureUnits"
              >
                <v-btn
                  value="celsius"
                  small
                >
                  °C
                </v-btn>

                <v-btn
                  value="fahrenheit"
                  small
                >
                  °F
                </v-btn>
              </v-btn-toggle>
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <Button
      text="Close"
      :on-click="() => $emit('close')"
    />
  </Dialogue>
</template>
<script>
import Dialogue from '../../atoms/dialogue/dialogue';
import Button from '../../atoms/button/button';

export default {
  name: 'SettingsDialogue',
  components: {
    Dialogue,
    Button,
  },
  props: {
    isVisible: { type: Boolean, default: false },
  },
  data() {
    return {
      temperatureUnits: this.$store.getters['user/isCelsiusTemperatureUnits'] ? 'celsius' : 'fahrenheit',
    };
  },
  methods: {
    setTemperatureUnits(value) {
      this.$store.dispatch('user/setTemperatureUnits', value === 'celsius');
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-content-container {
  margin-bottom: 20px;
}
</style>
