<template>
  <div
    id="nb-iot"
  >
    <div
      ref="pageContainer"
      class="page-container"
    >
      <div
        :class="{
          'is-large-screen': isLargeScreen
        }"
      >
        <div class="header">
          <img
            class="reelables-logo"
            :src="logo"
            :height="isLargeScreen ? 70 : 40"
            alt="Reelables logo"
          >
          <v-btn
            icon
            color="#fff"
            large
            @click="isSettingsOpen = !isSettingsOpen"
          >
            <v-icon>mdi-settings</v-icon>
          </v-btn>
        </div>
        <div
          ref="contentWrapper"
          class="content"
          :class="{
            'full-width': isLoading
          }"
        >
          <div
            v-if="topContentIsVisible"
            class="top-content"
          >
            <ErrorMessage :error-message="errorMessage" />
            <Loader
              :is-loading="isLoading"
              color="#45BEC1"
            />
          </div>
          <slot />
        </div>
        <div
          v-if="isLargeScreen"
          :key="backgroundResizeCounter"
          class="background-wrapper"
          :style="{
            'width': backgroundWidth + 'px',
            'left': backgroundLeft + 'px',
          }"
        >
          <slot name="background-content" />
        </div>
      </div>
      <div
        v-if="!isLargeScreen"
        class="footer"
      >
        <img
          class="reelables-logo"
          :src="logo"
          height="40"
          alt="Reelables logo"
        >
      </div>
    </div>
    <SettingsDialog
      :is-visible="isSettingsOpen"
      @close="isSettingsOpen = false"
    />
    <resize-observer @notify="setDimensions" />
  </div>
</template>
<script>
import LogoImage from '../../../assets/images/reelables-r-logo-teal.svg';
import ErrorMessage from '../../atoms/message/error-message';
import Loader from '../../atoms/loader/loader';
import SettingsDialog from '../../molecules/dialogues/settings-dialogue';

export default {
  components: {
    ErrorMessage,
    Loader,
    SettingsDialog,
  },
  props: {
    errorMessage: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: 0,
      timelineHeight: 0,
      backgroundLeft: 0,
      backgroundWidth: 0,
      backgroundResizeCounter: 0,
      hasLoadedData: false,
      isSettingsOpen: false,
    };
  },
  computed: {
    isLargeScreen() { return this.$vuetify.breakpoint.mdAndUp; },
    logo() { return LogoImage; },
    topContentIsVisible() { return this.errorMessage || this.isLoading; },
  },
  watch: {
    isLargeScreen() {
      this.setIsLargeScreen();
    },
  },
  created() {
    this.setIsLargeScreen();
  },
  methods: {
    setDimensions(_, counter = 0) {
      this.$nextTick(() => {
        this.$emit('changeContainerHeight', this.$refs.pageContainer.clientHeight);
        const pageWidth = this.$refs.pageContainer.clientWidth;
        const containerWidth = this.$refs.contentWrapper.clientWidth;
        if (counter < 10 && pageWidth === containerWidth) {
          // happens when resizing across breakpoint
          setTimeout(() => this.setDimensions(null, counter + 1), 100);
        } else {
          this.backgroundWidth = pageWidth - containerWidth - 60 - 17; // including scrollbar width
          this.backgroundLeft = this.$refs.contentWrapper.clientWidth + 60;
          this.backgroundResizeCounter += 1;
        }
      });
    },
    setIsLargeScreen() {
      this.$emit('isLargeScreen', this.isLargeScreen);
      if (this.isLargeScreen) {
        document.getElementsByTagName('html')[0].classList.add('is-large-screen');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import '@/styles/_updated-variables.scss';

.page-container {
  font-family: Helvetica-Neue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: space-between;

  .is-large-screen {
    display: flex;
    flex-direction: row;
    position: relative;
    .header {
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;
    }
    .content {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      min-width: 450px;
      max-width: 50%;
      max-height: 100vh;
      &.full-width {
        width: 100%;
        max-width: inherit;
      }
    }
    .background-wrapper {
      position: absolute;
      left: calc(50vw + 53px);
    }
  }
}
.header, .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 8px 0px;
}
.footer {
  background-color: $tealLight;
}
.top-content {
  padding-top: 20px;
  width: 100%;
}
</style>
<style lang="scss">
@import '@/styles/_updated-variables.scss';

html.is-large-screen {
  overflow-y: scroll !important;
}

#nb-iot {
  button.primary {
    color: $gray1 !important;
    caret-color: $gray1 !important;
  }

  .v-dialog__content.v-dialog__content--active {
    background-color: rgba($gray1, 0.5);
  }
}
</style>
