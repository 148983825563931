<template>
  <FullScreenTemperatureChart
    :is-visible="isVisible"
    has-data
    :asset-name="assetName"
    :temperature-data="temperatures"
    :space-data="spaceData"
    @close="$emit('close')"
  />
</template>
<script>
import FullScreenTemperatureChart from '../../molecules/dialogues/full-screen-temperature-chart';

export default {
  components: {
    FullScreenTemperatureChart,
  },
  props: {
    isVisible: { type: Boolean, default: false },
    assetId: { type: String, required: true },
    assetName: { type: String, required: true },
    temperatures: { type: Array, default: () => [] },
    timelineData: { type: Array, default: () => [] },
  },
  computed: {
    spaceData() {
      return this.timelineData
        .filter((item) => !item.isStart)
        .map((item) => ({
          assetId: this.assetId,
          spaceId: item.text,
          spaceName: item.text,
          enteredOn: item.startTimestamp,
          exitedOn: item.endTimestamp,
          hasExited: !!item.endTimestamp,
        }));
    },
  },
};
</script>
