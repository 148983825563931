var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"nb-iot"}},[_c('div',{ref:"pageContainer",staticClass:"page-container"},[_c('div',{class:{
        'is-large-screen': _vm.isLargeScreen
      }},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"reelables-logo",attrs:{"src":_vm.logo,"height":_vm.isLargeScreen ? 70 : 40,"alt":"Reelables logo"}}),_c('v-btn',{attrs:{"icon":"","color":"#fff","large":""},on:{"click":function($event){_vm.isSettingsOpen = !_vm.isSettingsOpen}}},[_c('v-icon',[_vm._v("mdi-settings")])],1)],1),_c('div',{ref:"contentWrapper",staticClass:"content",class:{
          'full-width': _vm.isLoading
        }},[(_vm.topContentIsVisible)?_c('div',{staticClass:"top-content"},[_c('ErrorMessage',{attrs:{"error-message":_vm.errorMessage}}),_c('Loader',{attrs:{"is-loading":_vm.isLoading,"color":"#45BEC1"}})],1):_vm._e(),_vm._t("default")],2),(_vm.isLargeScreen)?_c('div',{key:_vm.backgroundResizeCounter,staticClass:"background-wrapper",style:({
          'width': _vm.backgroundWidth + 'px',
          'left': _vm.backgroundLeft + 'px',
        })},[_vm._t("background-content")],2):_vm._e()]),(!_vm.isLargeScreen)?_c('div',{staticClass:"footer"},[_c('img',{staticClass:"reelables-logo",attrs:{"src":_vm.logo,"height":"40","alt":"Reelables logo"}})]):_vm._e()]),_c('SettingsDialog',{attrs:{"is-visible":_vm.isSettingsOpen},on:{"close":function($event){_vm.isSettingsOpen = false}}}),_c('resize-observer',{on:{"notify":_vm.setDimensions}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }