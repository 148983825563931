<template>
  <div class="asset-temperature-zones-chart-container">
    <Loader
      :is-loading="isLoading"
      with-padding-top
    />
    <ErrorMessage :error-message="errorMessage" />
    <TemperatureChart
      v-if="!isLoading && !hasError && hasData"
      :asset-name="assetName"
      :ble-id="bleId"
      :temperature-data="temperatureData"
      :asset-space-data="spaceData"
      include-download
      @openFullScreen="fullScreen = true"
    />
    <div v-if="!isLoading && !hasError && !hasData">
      No data
    </div>
    <FullScreenTemperatureChart
      :is-visible="fullScreen"
      :is-loading="isLoading"
      :has-error="hasError"
      :has-data="hasData"
      :asset-name="assetName"
      :ble-id="bleId"
      :temperature-data="temperatureData"
      :space-data="spaceData"
      include-download
      @close="fullScreen = false"
    />
  </div>
</template>
<script>
import TemperatureChart from '../../atoms/charts/temperature-chart';
import FullScreenTemperatureChart from '../../molecules/dialogues/full-screen-temperature-chart';
import Loader from '../../atoms/loader/loader';
import ErrorMessage from '../../atoms/message/error-message';

export default {
  components: {
    TemperatureChart,
    FullScreenTemperatureChart,
    Loader,
    ErrorMessage,
  },
  props: {
    assetId: { type: String, required: true },
    isAssetDataLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      fullScreen: false,
      gettingData: false,
    };
  },
  computed: {
    asset() {
      return this.$store.state.assets.assets.find(({ id }) => id === this.assetId);
    },
    assetName() { return (this.asset || {}).name; },
    bleId() { return (this.asset || {}).bleId; },

    assetInSpaces() {
      return this.$store.state.assets.assetInSpaces.assetsData
        .find((d) => d.assetId === this.assetId);
    },
    spaceData() { return (this.assetInSpaces || {}).items || []; },

    temperatureData() {
      return ((this.asset || {}).temperatures || {}).items || [];
    },
    hasData() { return this.temperatureData.length > 0; },

    errorMessage() {
      return this.$store.state.assets.assetInSpaces.errorMessage
      || this.$store.state.assets.errorMessage;
    },
    hasError() { return !!this.errorMessage; },
  },
  watch: {
    async isAssetDataLoading() {
      if (!this.isAssetDataLoading) {
        await this.getData();
      }
    },
  },
  async created() {
    if (!this.isAssetDataLoading) {
      await this.getData();
    }
  },
  methods: {
    async getData() {
      // only get data once
      if (!this.gettingData) {
        this.gettingData = true;
        await Promise.all([
          this.getAllAssetTemperatureData(),
          this.getAllAssetSpaceData(),
        ]);

        this.isLoading = false;
      }
    },
    async getAllAssetTemperatureData() {
      await this.$store.dispatch('assets/getAllAssetTemperatures', this.assetId);
    },
    async getAllAssetSpaceData() {
      await this.$store.dispatch('assets/assetInSpaces/getAllAssetInSpaces', this.assetId);
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-temperature-zones-chart-container {
  height: calc(100% - 10px);
}
</style>
